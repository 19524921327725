const SERVER_INFO = {
  ip: '120.76.230.110',
  port: '6605'
}

let mediaPortMap = {}
// function TtxH5Video(opt) {
//   this._initial(opt)
// }

const getComServerIp = function(lstSvrIp) {
  if (lstSvrIp != null && lstSvrIp.length > 0) {
    var host = window.location.host.split(':')
    if (host.length >= 1) {
      var addr = host[0]
      if (addr == 'localhost') {
        return '127.0.0.1'
      }
      for (var i = 0; i < lstSvrIp.length; ++i) {
        if (addr == lstSvrIp[i]) {
          return lstSvrIp[i]
        }
      }
    }
    return lstSvrIp[0]
  }
  return '127.0.0.1'
}

const getMinPort = function(ports) {
  var portNumMap = {}
  for (var index in mediaPortMap) {
    var port = mediaPortMap[index]
    if (portNumMap[port]) {
      portNumMap[port] = ++portNumMap[port]
    } else {
      portNumMap[port] = 1
    }
  }
  for (var i = 0; i < ports.length; i++) {
    port = ports[i]
    if (!portNumMap[port]) {
      portNumMap[port] = 0
    }
  }
  var portArr = Object.keys(portNumMap)
  portArr.sort(function(a, b) {
    return portNumMap[a] - portNumMap[b]
  })
  return portArr[0]
}
const getUrl = function(jsession, devIdno, channel, stream, mediaServer, type) {
  // console.log('this is serv', mediaServer)
  var url
  var urlPrefix = 'http://' + mediaServer.ip + ':' + mediaServer.port
  var params = []
  params.push(jsession)
  params.push(type)
  params.push(devIdno)
  params.push(channel)
  params.push(stream)
  params.push(0)
  params.push(0)
  url =
    urlPrefix + '/rtmp/' + new Date().getTime() + '/?' + btoa(params.join(','))
  return url
}
const createUrl = (jsession, devIdno, channel, stream, type) => {
  // const index = 0
  // const mediaServer = SERVER_INFO
  var serverInfo = { serverIp: SERVER_INFO.ip, serverPort: SERVER_INFO.port }
  var splicingSymbol = ':'
  //如果是http直接访问c++ 端口，否则需要nginx转发
  var webPort = ''
  if (location.protocol == 'https:') {
    splicingSymbol = '/'
    webPort = ':' + location.port
  }

  // let videoUrl = ''
  var action =
    location.protocol +
    '//' +
    serverInfo.serverIp +
    webPort +
    splicingSymbol +
    serverInfo.serverPort
  action +=
    '/3/1?MediaType=1&Type=0' +
    '&AVType=' +
    type +
    '&DevIDNO=' +
    devIdno +
    '&Channel=' +
    channel

  return action
}

const getVideo = (jsession, devIdno, channel) => {
  // console.log('getVideo', createUrl(jsession, devIdno, channel, 1, 2))
  return createUrl(jsession, devIdno, channel, 1, 2)
}
export { getVideo, getMinPort, getComServerIp, createUrl, getUrl, SERVER_INFO }

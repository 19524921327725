<template>
	<div>
		<header>
			<router-link :to="{path:'/sanxiang'}">宁宿徐排障监控管理平台</router-link>
			<!-- <span @click="back()">&lt; 返回</span> -->
			<!-- <ul style="right:85%;top:-8px;">
			<li @click="showinfo()"><img src="../assets/images/img30.png" title="调度"></li>
			<li @click="openZm()"><img src="../assets/images/img35.png" width="22px;" style="margin-left: 15px;" title="启动闸门" ></li>
			<li @click="openBjq()"><img src="../assets/images/img34.png" width="22px;" style="margin-left: 10px;" title="播放报警器" ></li>
			<li @click="closeBjq()"><img src="../assets/images/img36.png" width="22px;" style="margin-left: 1px;" title="取消播放" ></li>
			<li @click="showDialog()"><img src="../assets/images/img37.png" width="22px;" style="margin-left: 10px;" title="摄像头" ></li>
			</ul> -->
			<ul>
				<li @click="addDate()">{{nowTime}}</li>
				<li>欢迎您的登录！</li>
				<li><img src="../assets/images/user.png"></li>
				<li @click="logout()"><img src="../assets/images/logout.png"></li>
			</ul>
		</header>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				nowTime: '',
				shinfo: false,
				value: 0,
				checkdirect: [],
				fangxiang: '',
				zhuanghao: '',
				sgleixing: '',
				sgcar: '',
				menu: ['靖江(上行)', '盐城(下行)'],
				index: -1,
				accidents: ['事故', '故障'],
				accidx: -1,
				accidcars: ['小客车', '货车', '大客车', '危化品车'],
				acicar: -1,
				peokind: [],
				drives: [],
				drivesfj: [],
				zuanghao: [],
				holes: -1,
				jiashiren: [],
				jiashirenfj: [],
				dialogVisible: false,
				dialog2Visible: false,
				zjDriver: [],
				fjDriver: [],
				peopless: [],
				peoplesfj: [],
				zjusr: [],
				fjusr: [],
				selectedItem: [],
				selectedItemCar: [],
				selectDatetime:'',
				cars:'',
				checkCars:[],
				checkCarName:[],
				carsInfo:[],
				uuid:'',
				jjr:'',
				token:'72c3100a39e042e4a7ddb9a2646ac801',
				showPlayer: false,
				searchTableWidth:'',
				searchTableHeight:''
			}
		},
		created() {
		},
		mounted() {
			this.currentTime();
		},
		// 销毁定时器
		beforeDestroy: function() {
			if (this.getDate) {
				clearInterval(this.getDate) // 在Vue实例销毁前，清除时间定时器
			}
		},
		methods: {
			showDialog(){
				this.showPlayer = true;
				if(this.$refs.player!=undefined){
				this.$refs.player.play();
				}
			},
			initUUid(){
				var currentText = 'YSD5_'
				// 获取当前时间 
				var time = new Date()
				var month = time.getMonth() + 1
				var currentTime = time.getFullYear() + '' + month + '' + time.getDate() + '' + time.getHours() + '' + time
					.getMinutes() + '' + time.getSeconds()
				// 6位随机数 parseInt(Math.random()*(n-m+1)+m);生成一个从 m - n 之间的随机整数
				var currentNum = parseInt(Math.random() * (999999 - 100000 + 1) + 100000)
				this.uuid = currentText + currentTime + '.' + currentNum
			},
			async initData() {
			//获取当前时间
			var now= new Date();
			var monthn = now.getMonth()+1;
			var yearn  = now.getFullYear();
			var dayn = now.getDate();
			var h = now.getHours();
			var m =now.getMinutes();
			var s = now.getSeconds();
			this.selectDatetime = yearn+"-"+monthn+"-"+dayn+" "+h+":"+m+":"+s;
			},	
			open(msg) {
				this.$message({
					message: msg,
					type: 'success',
					showClose: true,
					center: true,
					duration: 2000
				});
			},
			hidinfo: function() {
				this.shinfo = !this.shinfo;
			},
			//返回上一层
			back() {
				this.$router.go(-1);
			},
			// 当前时间
			currentTime() {
				setInterval(this.getDate, 500)
			},
			getDate() {
				const year = new Date().getFullYear()
				const month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
				const date = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
				const hh = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()
				const mm = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
				const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
				// const week = new Date().getDay()
				this.nowTime = year + '年' + month + '月' + date + '日' + ' ' + hh + ':' + mm + ':' + ss
			},
			// 退出事件
			logout() {
				window.localStorage.removeItem('token');
				// this.$router.push("/");
				this.$router.replace({
					path: '/'
				})
				this.shinfo = !this.shinfo; //关闭弹框

				//此方法刷新页面时也会执行
				window.addEventListener('beforeunload', () => {
					localStorage.removeItem('token');
				});
			}
		}
	}
</script>
<style>
	@import "../assets/css/style.css";

	header span {
		position: absolute;
		left: 5%;
		top: 15px;
		font-size: 16px;
		color: #16f5e6;
		cursor: pointer;
		font-weight: normal;
	}

	header b {
		position: absolute;
		left: 12%;
		top: -2px;
		cursor: pointer;
	}

	header ul {
		position: absolute;
		right: 2%;
		top: -4px;
		margin: 0px;
	}

	header ul li {
		font-size: 16px;
		color: #16f5e6;
		display: inline-block;
		padding: 0px 6px;
		font-weight: normal;
	}

	header ul li:nth-child(3),
	header ul li:nth-child(4) {
		cursor: pointer;
		padding: 0px 15px;
	}

	header ul li img {
		vertical-align: top;
	}

	.shinfo h3 {
		position: absolute;
		left: 22.5%;
		top: 12%;
		z-index: 999999999999;
		cursor: pointer;
	}

	.alarmbox {
		position: absolute;
		left: 25%;
		top: 13%;
		z-index: 9;
		background: url(../assets/images/img28.png) no-repeat;
		background-size: 100% 100%;
		width: 50%;
		height: 63vh;
		overflow-y: auto;
	}

	.tpbox {
		margin: 20px 80px 0px;
	}

	.tpbox h4 {
		text-align: center;
		color: #16f5e6;
		background: url(../assets/images/img29.png) no-repeat center;
	}

	.tpbox .boxcells {
		padding: 0px 0px 15px;
	}

	.tpbox h5 {
		color: #16f5e6;
	}

	.tpbox dl dt {
		color: #16f5e6;
		font-size: 16px;
		font-weight: normal;
		float: left;
	}

	.max_vw dt {
		width: 18%;
	}

	.max_vw dd {
		width: 81% !important;
	}

	.tpbox dl dd {
		float: left;
		width: 93%;
	}

	.tpbox dl dd .actives {
		background: none #f1d332;
	}

	.tpbox dl dd span {
		background: #55b1bc;
		margin: 0px 10px 6px;
		padding: 6px 15px;
		border-radius: 5px;
		color: #000000;
		display: inline-block;
		cursor: pointer;
	}

	.boxbg {
		width: 100%;
		height: 100%;
		background: rgba(5, 0, 0, 0.7);
		position: absolute;
		top: 0;
		left: 0;
	}

	.el-slider__bar {
		background: none #f1d332 !important;
	}

	.el-slider__button {
		border-radius: 0 !important;
		background-color: #f1d332 !important;
		border: medium none !important;
	}

	.el-slider__runway {
		background-color: #37554a !important;
	}

	.block {
		padding: 0px 0px 0px 57px;
	}

	.block b {
		float: left;
		display: block;
		color: #f1d332;
		padding: 10px 0px 0px 10px;
	}

	.weui-form_area {
		position: absolute;
		bottom: 17%;
		left: 31%;
		z-index: 99;
		width: 38%;
		background: -webkit-linear-gradient(0deg, #0E9AC2 0%, #16F5E6 100%);
		/* Safari 5.1 - 6.0 */
		background: -o-linear-gradient(0deg, #0E9AC2 0%, #16F5E6 100%);
		/* Opera 11.1 - 12.0 */
		background: -moz-linear-gradient(0deg, #0E9AC2 0%, #16F5E6 100%);
		/* Firefox 3.6 - 15 */
		background: linear-gradient(0deg, #0E9AC2 0%, #16F5E6 100%);
		border-radius: 50px;
		padding: 15px 0px;
		text-align: center;
		font-size: 20px;
		color: #fff;
		cursor: pointer;
	}

	.el-input-number--small .el-input-number__decrease,
	.el-input-number--small .el-input-number__increase {
		background: none !important;
		margin: 0 !important;
		padding: 0 !important;
		border-radius: 0 !important;
		color: ##606266 !important;
	}

	.el-dialog {
		background: rgba(31, 57, 60, 0.9) !important;
	}

	.el-dialog__body {
		height: 45vh!important;
		overflow-y: auto;
	}
	.dialog-footer{
		text-align: center;
	}
	.dialog-footer button{
		width:45%;
		font-size: 22px;
	}
	.dialogClass .el-dialog__body {
		padding: 20px;
		padding-top: 10px;
		padding-bottom: 20px;
		margin-left: 20px;
		color: #606266;
		font-size: 14px;
		height: 100% !important;
	}
</style>

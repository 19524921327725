<template>
	<div>
		<top></top>
		<div style="position: relative; top:-38px;">
			<div class="amap-wrapper">
				<el-amap ref="map" :amap-manager="amapmanager" :center="center" :zoom="zoom" :events="mapEvents"
					vid="amapDemo">
					<el-amap-marker v-for="(marker, index) in showonlinecars" :key="'online' + index"
						:position="marker.position" :icon="marker.icon" :label="marker.label"></el-amap-marker>
				</el-amap>
			</div>
			<div class="xuanfu">
				<div class="maptits">
					<h3><img src="../assets/images/dates.png" width="20" height="20"> 实时数据</h3>
				</div>
				<div class="tooltips">
					<ul class="clearfix" v-for="(item,index) in showCarInfo" :key="index">
						<li>车牌号 <span class="carno">{{item.name}}</span></li>
						<li v-show="item.online == 1">状 态 <span class="carlabel">在线</span>
							&nbsp;&nbsp;&nbsp;
							<span @click="showDialog(item.deviceno)" style="cursor: pointer;" v-show="item.online == 1">
								<img :src="open" style="margin-left:5px;with: 20px;height: 20px;margin-top: -2px;">
							</span>
						</li>
						<li v-show="item.online != 1">状 态 <span class="carlabel green">不在线</span></li>
						<!-- <li>速 度 <span class="carmiles">{{item.speed}} km/h</span></li> -->
						<!-- <li>人 员 <span class="carman">{{item.workername}}</span></li> -->
					</ul>
				</div>
			</div>
			
			<el-dialog :visible.sync="showPlayer" class="dialogClass" @close="closeVideo">
				<player ref="player" :jsession="jsession" :devIdno="devIdno" :width="searchTableWidth"
					:height="searchTableHeight" @sendControl="sendControl"></player>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	// import {AMapManager} from "vue-amap";
	import Vue from "vue";
	import top from './header.vue'
	import VueAMap from "vue-amap";
	import player from '../components/player/live-player'
	Vue.use(VueAMap);

	let amapmanager = new VueAMap.AMapManager();
	// import serviceIcon from "../../assets/images/icon_map_r_service.png";
	import onlinecar from '../assets/images/smallcar.png';
	import open from '../assets/images/open.png';
	// import close from '../assets/images/close.png';
	import {
		getRegion,
		getCarsByRid,
		getOnlineStatus,
		getGpsStatus,
		control
	} from '../js/index.js'
	VueAMap.initAMapApiLoader({
		key: "843930cc05849660eeb95bf3eae8bfa5",
		plugin: [
			"AMap.Autocomplete",
			"AMap.PlaceSearch",
			"AMap.Scale",
			"AMap.OverView",
			"AMap.ToolBar",
			"AMap.MapType",
			"AMap.PolyEditor",
			"AMap.CircleEditor",
			'AMap.MarkerClusterer',
		],
		uiVersion: "1.0.11", // 版本号
		// 默认高德 sdk 版本为 1.4.4
		v: "1.4.15"
	});


	export default {
		data() {
			return {
				angle: 0,
				zoom: 13,
				zoomEnable: true, //是否可滚轮缩放
				dragEnable: true, //是否可拖拽
				resizeEnable: true, //是否监控地图容器尺寸变化
				amapmanager,
				mapEvents: {
					init(o) {
						// o.setMapStyle("amap://styles/f128f2e82f1998e65744a4cb742a41c3");
						o.setMapStyle("amap://styles/blue");
						// setTimeout(() => {
						// 	let cluster = new AMap.MarkerClusterer(o, self.markerRefs, {
						// 		styles: sts,
						// 		gridSize: 10
						// 		// renderCluserMarker: self._renderCluserMarker
						// 	});
						// }, 1000);
					},
					complete: () => {
						this.$refs.map.$$getInstance().setFitView()
					}
				},
				center: [117.576266, 34.238267], //中心点
				pois: [],
				onlinecars: [],
				currentindex: 0,
				lines: [],
				movecar: '',
				ifshow: false,
				ifquan: false,
				showonlinecars: [],
				clearId: '',
				carsInfo: [],
				optionSelected: '全部',
				markerRefs: [],
				chatTimer: '',
				showCarInfo: '',
				isShow: false,
				showAccident: [],
				open: open,
				showPlayer: false,
				searchTableWidth: 0,
				searchTableHeight: 0,
				devIdno: 0,
				devIds: [],
				jsession: 'e83e795852044e499bb02dca2221b01f',
				devIdStatus: '',
				token: '72c3100a39e042e4a7ddb9a2646ac801',
				gpsList: ''
			}
		},
		components: {
			top,
			player
		},
		created() {
			this.devIdStatus = new Map();
			this.gpsList = new Map();

			this.chatTimer = setInterval(() => {
				this.init();
			}, 30000)

			// setInterval(() => {
			// 	this.$router.go(0);
			// }, 3600000)
		},
		mounted() {
			this.initData()
			//this.xunhuan()
			// this.getpoiinfo()
			// this.getAccident();
			window.onresize = () => {
				this.widthHeight(); // 自适应高宽度
			};
			this.$nextTick(function() {
				this.widthHeight();
			});
		},
		methods: {
			sendControl(command) {
				// let url = "/sxtapi/StandardApiAction_sendPTZControl.action?"+
				// "jsession="+this.jsession+"&DevIDNO="+this.devIdno+"&Chn=0&Command="+command+"&Speed=10&Param=1";
				control(this.jsession, this.devIdno, command).then(res => {
					console.log(res);
				})
			},
			closeVideo() {
				this.$refs.player.destroyPlayer()
			},
			showDialog(deviceId) {
				this.devIdno = deviceId;
				this.showPlayer = true;
				this.$nextTick(() => {
					this.$refs.player.play();
				})
			},
			widthHeight() {
				this.searchTableHeight = parseInt(window.innerHeight / 2).toString();
				this.searchTableWidth = parseInt(window.innerWidth / 2.2).toString();
			},
			init() {
				// cluster.clearMarkers();
				this.onlinecars = [];
				this.showonlinecars = [];
				this.carsInfo = [];
				this.markerRefs = [];
				this.showAccident = [];
				this.initData()
				// this.xunhuan();
				// this.getAccident();
			},
			bd_decrypt(bd_lng, bd_lat) {
				var X_PI = (Math.PI * 3000.0) / 180.0
				var x = bd_lng - 0.0065
				var y = bd_lat - 0.006
				var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI)
				var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI)
				var gg_lng = z * Math.cos(theta)
				var gg_lat = z * Math.sin(theta)
				return {
					lng: gg_lng,
					lat: gg_lat
				}
			},
			initData() {
				this.showCarInfo = []
				let that = this;
				getRegion().then(res => {
					getCarsByRid(res.data[0].id).then(res => {
						let devIds = '';
						for (let i = 0; i < res.length; i++) {
							that.showCarInfo.push(res[i]);
							devIds = devIds + res[i].deviceno + ','
						}
						// 获取车辆设备在线状态
						getOnlineStatus(this.jsession, devIds).then(res => {
							let gpsDevnos = ''

							res.data.onlines.forEach(item => {
								that.showCarInfo.forEach(car => {
									if (car.deviceno == item.did) {
										car.online = item.online
									}
								})
								if (item.online === 1) {
									gpsDevnos = gpsDevnos + item.did + ','
								}
							})
							getGpsStatus(this.jsession, devIds).then(res => {
								res.data.status.forEach(item => {
									if (item.mlat != '0') {
										that.showCarInfo.forEach(car => {
											if (car.deviceno == item.id) {
												car.lat = item.mlat
												car.lng = item.mlng
												car.sp = item.sp
												car.gt = item.gt
											}
										})
									}
								})

								that.showCarInfo.forEach(car => {
									if (car.lng != undefined) {

										let result = this.bd_decrypt(car.lng, car
											.lat)
										let position = [result.lng, result.lat];
										let status = '在线';
										if (car.online == '0') {
											status = '离线'
										}
										that.center = position;
										that.showonlinecars.push({
											name: car.name,
											position: position,
											icon: onlinecar,
											// angle: 180,
											extData: {
												name: car.name,
												pos: car.pos,
												status: status,
											},
											label: {
												content: "<div class='car-gps-label'>" +
													car.name +
													'(<font style="color:green;">' +
													status +
													'</font>)<br>' + car
													.gt + '<br>' +
													parseInt(
														car.sp) / 10 +
													'km/h' + "</div>",
												offset: [20, 0]
												// content: '<div style="text-align:center;background-color: hsla(180, 100%, 50%, 0.7);height: 24px; width: 24px; border: 1px solid hsl(180, 100%, 40%); border-radius: 12px; box-shadow: hsl(180, 100%, 50%) 0px 0px 1px;"></div>',
											},
											events: {
												init(o) {
													that.markerRefs.push(
													o);
												}
											}
										})

									}
								})
								this.$nextTick(() => {
									this.$refs.map.$$getInstance().setFitView()
								})
							})
						})
					})
				})
			}
		}
	}
</script>
<style>
	.car-label-info {
		transform-origin: 0 0;
		/* position: absolute; */
	}

	.amap-wrapper {
		padding: 10px 0px 0px;
		width: 100%;
		height: 100vh;
	}

	.amap-marker-label {
		position: absolute;
		z-index: 2;
		background-color: transparent;
		border: none;
		white-space: nowrap;
		cursor: default;
		padding: 3px;
		font-size: 12px;
		line-height: 14px;
		color: #bce2ff;
	}

	.car-info {
		display: flex;
		flex-direction: column;
	}

	.car-gps-label {
		color: white;
		transform-origin: 0 20px;
		width: 150px;
		font-weight: 400;
	}

	.showrute {
		border: none;
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	.showquan {
		border: none;
		position: absolute;
		bottom: 60px;
		right: 20px;
	}

	.height100 {
		height: 100vh;
	}

	.height58 {
		height: 58vh;
	}

	.noquan {
		position: relative;
	}

	.quanmap {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1000;
	}

	.amap-logo {
		right: 10px !important;
		display: none !important;
	}

	.map_search {
		position: absolute;
		top: 2%;
		right: 10%;
	}

	.map_search select {
		border: 1px solid #01b6af;
		line-height: 30px;
		background: none #162d30;
		color: #01b6af;
		padding: 3px 10px;
		border-radius: 5px;
	}

	.maptits h3 {
		font-size: 20px;
		margin: 0px;
		color: #00d6cb;
	}

	.maptits h3 img {
		vertical-align: bottom;
	}

	.xuanfu {
		position: absolute;
		top: 10%;
		left: 2%;
		width: 22%;
		padding: 15px 20px;
		background: url(../assets/images/img33.png) no-repeat;
		background-size: 100% 100%;
	}

	.tooltips {
		margin: 15px 0px 0px;
		height: 680px;
		overflow-y: auto;

	}

	.tooltips ul {
		background: url(../assets/images/img32.png) no-repeat;
		background-size: 100% 100%;
		padding: 10px;
		margin: 5px 0px;
	}

	.tooltips ul li {
		width: 50%;
		float: left;
		color: #01cfc4;
		line-height: 25px;
	}

	.tooltips ul .carno {
		color: #f4e663;
		padding: 0px 0px 0px 5px;
	}

	.tooltips ul .carlabel {
		background: #cec53e;
		color: #444;
		padding: 0px 8px;
		border: 1px solid #f1c121;
		margin: 0px 0px 0px 5px;
		/* border-radius: 16px; */
	}

	.tooltips ul .green {
		background: #236d81;
		color: #ffffff;
		border: 1px solid #20c9ee;
		padding: 0px 15px;
	}

	.tooltips ul .carmiles {
		color: #ffffff;
		padding: 0px 0px 0px 14px;
	}

	.tooltips ul .carman {
		color: #ffffff;
		padding: 0px 0px 0px 5px;
	}

	.accident-label {
		position: absolute;
		background: url('../assets/images/border.png');
		background-size: 100% 100%;
		/* background-repeat:no-repeat; */
		z-index: 2;
		white-space: nowrap;
		cursor: default;
		padding: 3px 10px 3px 10px;
		font-size: 12px;
		line-height: 14px;
		color: #fff;
		margin-left: -7px;
		text-shadow: white 0px 0px 10px, white 0px 0px 20px, white 0px 0px 30px, rgb(127, 255, 0) 0px 0px 40px, rgb(127, 255, 0) 0px 0px 70px, rgb(127, 255, 0) 0px 0px 80px, rgb(127, 255, 0) 0px 0px 100px;
	}

	.dialogClass .el-dialog__body {
		padding: 20px;
		padding-top: 10px;
		padding-bottom: 20px;
		margin-left: 20px;
		color: #606266;
		font-size: 14px;
		height: 100% !important;
	}
</style>

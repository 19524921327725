<template>
	<div id="live">
		<div id="cmsv6flash"></div>
		<div>
			<!-- <video id="videoElement" controls autoPlay :width="width" :height="height"></video> -->
		</div>
		<!-- <button @click="play">播放</button> -->
		<!-- <button @click="stopPlay">STOP</button> -->
		<div id="tab_state_1" class="tab_state_pane ui-tabs-panel ui-corner-bottom ui-widget-content"
			aria-labelledby="ui-id-2" role="tabpanel" aria-hidden="true">
			<div class="state_1_yt">
				<div class="fl icon_yt_box">
					<a class="icon_yt_0" @mousedown="sendPTZControl(4);" @mouseup="sendPTZControl(19);" title="左上"></a>
					<a class="icon_yt_1" @mousedown="sendPTZControl(2);" @mouseup="sendPTZControl(19);" title="上"></a>
					<a class="icon_yt_2" @mousedown="sendPTZControl(5);" @mouseup="sendPTZControl(19);" title="右上"></a>
					<a class="icon_yt_3" @mousedown="sendPTZControl(0);" @mouseup="sendPTZControl(19);" title="左"></a>
					<a class="icon_yt_4" @mousedown="sendPTZControl(18);" title="自动巡航"></a>
					<a class="icon_yt_5" @mousedown="sendPTZControl(1);" @mouseup="sendPTZControl(19);" title="右"></a>
					<a class="icon_yt_6" @mousedown="sendPTZControl(6);" @mouseup="sendPTZControl(19);" title="左下"></a>
					<a class="icon_yt_7" @mousedown="sendPTZControl(3);" @mouseup="sendPTZControl(19);" title="下"></a>
					<a class="icon_yt_8" @mousedown="sendPTZControl(7);" @mouseup="sendPTZControl(19);" title="右下"></a>
					<!-- 	<div id="yt_handle" class="yt ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content ui-slider-disabled ui-state-disabled"
					style="width:92px; margin:3px;">
						<div class="ui-slider-range ui-corner-all ui-widget-header ui-slider-range-min" style="width: 23.5294%;"></div><span
						tabindex="0" class="ui-slider-handle ui-corner-all ui-state-default" style="left: 23.5294%;"></span>
					</div> -->
				</div>
				<div class="player-param">
					<a id="talkbackBtn" class="button button-primary button-rounded button-small"
						@click="startTalkback()">对讲</a>
					<a id="talkbackBtn" class="button button-primary button-rounded button-small" style="margin-left: 20px;"
						@click="stopTalk()">停止</a>	
				</div>
				<div class="fr yt_state2">
					<span>
						<a class="icon_f0_0" @mousedown="sendPTZControl(8);" @mouseup="sendPTZControl(19);"
							title="焦距变小"></a><a class="icon_f0_1" @mousedown="sendPTZControl(9);"
							@mouseup="sendPTZControl(19);" title="焦距变大"></a>
					</span>
					<span>
						<a class="icon_y0_0" @mousedown="sendPTZControl(10);" @mouseup="sendPTZControl(19);"
							title="光圈缩小"></a><a class="icon_y0_1" @mousedown="sendPTZControl(11);"
							@mouseup="sendPTZControl(19);" title="光圈放大"></a>
					</span>
					<span>
						<a class="icon_k0_0" @mousedown="sendPTZControl(12);" @mouseup="sendPTZControl(19);"
							title="焦点后调"></a><a class="icon_k0_1" @mousedown="sendPTZControl(13);"
							@mouseup="sendPTZControl(19);" title="焦点前调"></a>
					</span>
					<!-- 	<span>
						<a class="icon_light0_0" @mousedown="sendPTZControl(14);" title="打开灯光"></a><a class="icon_biao0_1" @mousedown="sendPTZControl(16);"
						title="打开雨刷"></a>
					</span> -->
				</div>
				<div class="spacer"></div>
			</div>
		</div>
	</div>

</template>

<script>
	import flvjs from 'flv.js'
	import {
		getVideo,
		getUrl
	} from './get-video'
	// import cmsv6 from './cmsv6player.min.js'
	// import axios from 'axios'
	export default {
		name: 'live-player',
		props: {
			autoPlay: {
				type: Boolean,
				default: true
			},
			jsession: String,
			devIdno: String,
			width: String,
			height: String
		},
		data() {
			return {
				flvPlayer: null,
				channel: 0,
				type: 2,
				videoUrl: '',
				videoWidth: 800,
				videoHeight: 400,
				swfobject: null,
				serverIp: '120.76.230.110',
				serverPort: '6605',
				isInitFinished: false
			}
		},
		mounted() {},
		created() {
			//this.initUrl()
			// this.initCmsV6Player()
		},
		methods: {
			sendPTZControl(type) {
				this.$emit('sendControl', type);
			},
			setWidth(width, height) {
				this.videoWidth = width;
				this.videoHeight = height;
			},
			initCmsV6Player() {
				var _isVodMode = 0;
				//赋值初始化为未完成
				this.isInitFinished = false;
				//视频插件宽度
				var width = this.width;
				//视频插件高度
				var hieght = this.height;
				var options = {
					domId: "cmsv6flash",
					isVodMode: _isVodMode == "1" ? true : false,
					width: width,
					height: hieght,
					lang: "en",
					screenshot:false
				}
				this.swfobject = new window.Cmsv6Player(options);
				this.initFlash();
			},
			initFlash() {
				if (typeof this.swfobject == "undefined" ||
					typeof this.swfobject.setWindowNum == "undefined") {
					setTimeout(this.initFlash, 50);
				} else {
					//初始化插件语言
					this.swfobject.setLanguage('en');
					//先将全部窗口创建好
					this.swfobject.setWindowNum(36);
					//再配置当前的窗口数目
					this.swfobject.setWindowNum(1);
					//设置服务器信息
					this.swfobject.setServerInfo(this.serverIp, this.serverPort);
					this.isInitFinished = true;

					// 监听回放Radio
					// monitorPlaybackRadio()

				}
				this.startPlay()
			},
			startTalkback() {
				if (!this.isInitFinished) {
					return;
				} else {
					//会话号
					var jsession = this.jsession;
					//设备号
					var devIdno = this.devIdno;
					this.swfobject.setTalkParam(1);
					var ret = this.swfobject.startTalkback(jsession, devIdno, 0);
					//flash播放器通过startTalkback返回值判断来判断, h5播放器通过 onTtxVideoMsg 消息回调来判断 type=isTalking表示正在对讲中，type=showDownLoadDialog表示需要下载pcm采集工具
					//返回 0成功，1表示正在对讲，2表示没有mic，3表示禁用了mic
					console.log('ret', ret);
					if (ret == 0) {
						alert("开启成功")
					} else if (ret == 1) {
						alert("对讲中")
					} else if (ret == 2) {
						alert("没有麦克风")
					} else if (ret == 3) {
						//alert(lang.micStop);
						alert("麦克风被禁用")
					}
					//flash end
				}
			},
			stopLiveVideo() {
				if (!this.isInitFinished) {
					return;
				} else {
					//窗口下标
					this.swfobject.stopVideo(0);
				}
			},
			stopTalk() {
				if (!this.isInitFinished) {
					return;
				} else {
					this.swfobject.stopTalkback();
					alert("对讲关闭")
				}
			},
			startPlay() {
				if (!this.isInitFinished) {
					return;
				}
				//窗口下标
				var index = 0
				//jsession会话号
				var jsession = this.jsession;
				//设备号
				var devIdno = this.devIdno
				//通道号
				var channel = 0
				//码流
				var stream = 1
				//最小缓冲时长
				this.swfobject.setBufferTime(index, 2);
				//最大缓冲时长
				this.swfobject.setBufferTimeMax(index, 6);
				//先停止视频窗口
				this.swfobject.stopVideo(index);
				//设置窗口标题
				var title = '森德邦技术支持';
				this.swfobject.setVideoInfo(index, title);
				//播放视频
				this.swfobject.startVideo(index, jsession, devIdno, channel, stream, true);
				
			},
			initUrl() {
				const that = this
				const url = getVideo(this.jsession, this.devIdno, 0)
				var l = {
					method: "GET",
					// headers: s,
					mode: "cors",
					cache: "default",
					referrerPolicy: "no-referrer-when-downgrade"
				};

				fetch(url, l).then(res => res.json()).then(res => {
					let mediaServer = {}
					mediaServer.port = res.server.clientPort
					mediaServer.ip = res.server.clientIp

					var url = getUrl(
						that.jsession,
						that.devIdno,
						that.channel,
						1,
						mediaServer,
						that.type
					)
					that.videoUrl = url
					console.log(that.videoUrl, 'that.videoUrl')
					that.initPlayer(url);
				})
			},
			initPlayer(url) {
				let that = this;
				if (flvjs.isSupported()) {
					let videoElement = document.getElementById('videoElement')
					videoElement.addEventListener('play', function() {
						// console.log('play')
						that.flvPlayer.load()
						that.flvPlayer.play()
					});
					videoElement.addEventListener('pause', function() {
						// console.log('pause')
						that.stopPlay();
					});
					this.flvPlayer = flvjs.createPlayer({
						type: 'flv',
						isLive: true,
						hasAudio: false,
						lazyLoad: false,
						url: url
					})
					this.flvPlayer.attachMediaElement(videoElement)
					// 是否自动播放
					if (this.autoPlay) {
						this.flvPlayer.load()
						this.flvPlayer.play()
					}
				}
			},
			changeUrl() {
				this.flvPlayer = flvjs.createPlayer({
					type: 'flv',
					isLive: true,
					hasAudio: false,
					url: this.videoUrl
				})
				let videoElement = document.getElementById('videoElement')
				this.flvPlayer.attachMediaElement(videoElement)
				// 是否自动播放
				if (this.autoPlay) {
					this.flvPlayer.load()
					this.flvPlayer.play()
				}
			},
			play() {
				// console.log(this.flvplayer, 'this.flvplayer')
				// if(this.flvplayer!=null){
				// this.initUrl();
				// }
				this.initCmsV6Player()
			},
			stopPlay() {
				// console.log(this.flvplayer, 'this.flvplayer')
				this.flvPlayer.pause()
				this.flvPlayer.unload()
				this.flvPlayer.detachMediaElement()
				this.flvPlayer.destroy()
				this.flvPlayer = null
			},
			destroyPlayer() {
				// if (this.flvPlayer != null) {
				// 	this.flvPlayer.pause()
				// 	this.flvPlayer.unload()
				// 	this.flvPlayer.detachMediaElement()
				// 	this.flvPlayer.destroy()
				// 	this.flvPlayer = null
				// }
				this.stopLiveVideo()
			}
		},

		destroyed() {
			this.destroyPlayer()
		}
	}
</script>
<style>
	@import "../../assets/css/bin.css";
	.player-param {
		width: 12.5rem!important;
		padding: 0 0 10px;
		white-space: normal;
		word-break: break-all;
		overflow: hidden;
		display: inline-block;
		margin-left: 40%;
		margin-top: 5%;
	}
	.button-small {
		font-size: 12px;
		height: 30px;
		line-height: 30px;
		padding: 0 30px;
	}

	.button-rounded {
		border-radius: 4px;
	}

	.button-primary,
	.button-primary-flat {
		background-color: #1B9AF7;
		border-color: #1B9AF7;
		color: #FFF;
	}

	.button {
		font-weight: 300;
		font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
		text-decoration: none;
		text-align: center;
		margin: 0;
		display: inline-block;
		appearance: none;
		cursor: pointer;
		border: none;
		box-sizing: border-box;
		transition-property: all;
		transition-duration: .3s;
	}
</style>
